@import "shared/styles/tools";

.donationOverlay {
  position: fixed;
  bottom: 20px;
  z-index: 9999;
  opacity: 1;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  @include breakpoint(md) {
    bottom: 20px;
    left: unset;
    transform: unset;
  }

  @include breakpoint(sm) {
    bottom: 20px;
    left: unset;
    transform: unset;
    padding: 0px 16px;
  }
  
}


.donationBanner {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  @include breakpoint(md) {
    flex-direction: row;
    margin: 0px 16px;
  }
}


.closeButton {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.headerContainer {
  background: #500083;
  color: var(--brand-primary);
  width: 45%;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 16px 20px;
  padding-right: clamp(20px, 2%, 75px);
  border-radius: 15px 0px 0px 15px ;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
  text-align: right;
  font-family: 'DIN Condensed';
  font-weight: 700;
  @include breakpoint(sm) {
    width: 100%;
    font-size: 1.5rem;
    align-items: center;
    padding: 16px 4px;
    border-radius: 15px 15px 0px 0px;
  }
}

.paywallHeader{
  line-height: normal;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 55%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  background-color: #6e35a8;
  border-radius: 0px 15px 15px 0px ;

  @include breakpoint(sm) {
    border-radius: 0px 0px 15px 15px ;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0px;
    width: 100%;
  }
}

.bodyInner {
  max-width: 600px;
  // max 48px min 24px 
  padding-right: clamp(20px, 2%, 48px);
  @include breakpoint(sm) {
    padding: 0 38px;
    font-weight: 500;

  }
}

.donateButton {
  width: 40%;
  max-width: 280px;
  min-width: 200px;
  margin-top: 1rem;
  margin-right: auto;
  background-color: var(--brand-primary);
  color: var(--text-primary);
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  font-weight: bold;
  cursor: pointer;
  overflow: unset;
  border: none;

  @include breakpoint(sm) {
    margin: 1.5rem auto 0 auto;
  }
}