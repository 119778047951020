@import "shared/styles/tools";

.root {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.25em;
  pointer-events: none;
  display: flex;

  &.isMobile {
    display: none;
  }

  @include breakpoint(sm) {
    width: 2em;
    flex: 0 1 50%;
    max-width: 250px;
    min-width: 0;
    display: none;

    &.isMobile {
      display: flex;
    }
  }

  * {
    pointer-events: all;
  }

  :global(.video-js) & :global(.vjs-play-control.vjs-button > .vjs-icon-placeholder):before {
    font-size: 1.8em;
    position: relative; // otherwise it's misaligned in firefox
    @include breakpoint(lg) {
      font-size: 1.5em;
    }
  }
}