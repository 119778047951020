@import "shared/styles/tools";

@import "shared/styles/tools";
@import "shared/styles/elements";

.hidden {
  visibility: hidden;
}

.root {
  @include btnPill();
  @include btnDark();
  
  cursor: pointer;
  position: absolute;
  left: var(--padding-lg);
  z-index: 2;

  bottom: var(--padding-lg);
  font-size: 1rem;;
  padding: .15rem 1.25rem;

  :global(.vjs-has-started) & {
    visibility: hidden;
  }

  & svg {
    fill: white;
    height: 21px;
    width: 21px;
  }

  & span {
    margin-left: var(--padding-sm);
  }

  &:hover {
    border-color: var(--brand-primary);
    color: var(--brand-primary);
    
    svg {
      fill: var(--brand-primary);
    }
  }
}