@import "shared/styles/tools";

.root {
  flex: 1;
  display: flex;
  padding: 2px spacing(1);
  background-color: transparent;
  color: #ffffff;

  &.selected {
    background-color: #ffffff;
    color: #141414;
  }

  justify-content: center;

  span {
    text-transform: none; // override the uppercase from the font.
  }
  &:hover {
    background-color: #d5d5d5;
    color: #141414;
    &.selected {
      background-color: #ffffff;
    }
  }
}
