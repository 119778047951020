@import "shared/styles/tools";

@mixin volume {
  :global(.video-js) & :global(.vjs-volume-panel.vjs-volume-panel-horizontal) {
    width: 2em;
    align-items: center;
    transition: width 1s;

    & :global(.vjs-mute-control) {
      width: 2em;
    }
    & :global(.vjs-button > .vjs-icon-placeholder:before) {
      line-height: 2.758em;
      @include breakpoint(sm) {
        font-size: 1.5em;
        line-height: 1.879em;
      }
    }
    &:global(.vjs-hover),
    &:global(.vjs-slider.active),
    &:active {
      width: 7em;
      transition: width 0.1s;
    }

    :global(.vjs-slider-horizontal .vjs-volume-level):before {
      font-size: 0.9em;
      line-height: 0.9em;
    }
    :global(.vjs-volume-control) {
      margin-right: 0px;
      display: flex;
      align-items: center;
      height: 3em;
    }

    :global(.vjs-volume-level) {
      background: #00aaff;
    }
    :global(.vjs-volume-level)::before {
      color: #00aaff;
      pointer-events: none; // To avoid activating the hover while animation is fading out.
    }
    :global(.vjs-volume-control .vjs-slider-bar) {
      background: rgba(20, 20, 20, 0.6);
    }
  }
}
