.dialogOverlay {
    position: fixed;
    inset: 0;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.2s ease-in-out;
  
    &[data-state='closed'] {
      animation: fadeOut 0.2s ease-in-out;
    }
  }
  
  .dialogContent {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 50;
    display: grid;
    width: 100%;
    max-width: 960px;
    padding: 2rem;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    animation: zoomIn 0.2s ease-in-out;
  
    &[data-state='closed'] {
      animation: zoomOut 0.2s ease-in-out;
    }
  }
  
  .dialogClose {
    position: absolute;
    right: 0rem;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    background-color: transparent;
    border: none;
    cursor: pointer;
  
    &:hover {
      opacity: 1;
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--focus-ring);
    }
  }
  
  .dialogHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  
    @media (min-width: 640px) {
      text-align: left;
    }
  }
  
  .dialogFooter {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
  
    @media (min-width: 640px) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  
  .dialogTitle {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
  }
  
  .dialogDescription {
    font-size: 0.875rem;
    color: var(--text-muted);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes zoomIn {
    from {
      transform: translate(-50%, -50%) scale(0.95);
    }
    to {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  
  @keyframes zoomOut {
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(-50%, -50%) scale(0.95);
    }
  }
