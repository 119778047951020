.clipboardButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
}


.tooltip {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  display: inline-block;
  & span {
    z-index: 1; // it shouldnt hide behind anything
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    bottom: -150%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    & :after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
      transform: rotate(180deg);
    }
  }
}

/* Show the tooltip text */
.visible {
  & span {
    visibility: visible;
    opacity: 1;
  }
}

.iconLink {
  display: block;
  
  svg {
    fill: black;

    &:hover {
      fill: var(--brand-primary);
    }
  }
}
