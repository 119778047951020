.loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: -webkit-translate(-50%, -50%) -webkit-rotate(0deg);
    width: 100px;
    z-index: 10;
}
.loadingSpinner.hidden {
    visibility: hidden;
}
.loadingSpinner.visible {
    visibility: visible;
    animation: spinning 2.5s infinite;
    animation-timing-function: linear;
}
@keyframes spinning {
    from {
     transform: translate(-50%, -50%) rotate(0deg);
     -webkit-transform: -webkit-translate(-50%, -50%) -webkit-rotate(0deg);
   }
   to {
     transform: translate(-50%, -50%) rotate(360deg);
     -webkit-transform: -webkit-translate(-50%, -50%) -webkit-rotate(360deg);
   }
 }