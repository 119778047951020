:global(.player-wrapper).preserveAspect {
  aspect-ratio: 16 / 9;
  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  height: auto;
}