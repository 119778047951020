@import "shared/styles/tools";

.container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: var(--padding-16-24);
  padding-right: var(--padding-16-24);
  width: 100%;
  --col-width: 12;
  gap: var(--gap-size);
  flex-basis: calc(var(--col-width) / var(--columns) * 100%);
  
  .container {
    --padding-left: 0;
    --padding-right: 0;
    padding-left: var(--padding-left);
    padding-right: var(--padding-right);
  }
}


.full {
  padding: 0;
}

.item {
  display: block;
  box-sizing: border-box;
  margin: 0;
  --col-width: 12;
  flex-basis: calc((var(--col-width) * 100% - (var(--gap-size, 0px) * (var(--columns) - var(--col-width)  ))) / var(--columns));
  max-width: calc((var(--col-width) * 100% - (var(--gap-size, 0px) * (var(--columns) - var(--col-width)  ))) / var(--columns));
}

.nowrap {
  flex-wrap: nowrap;
}

.nowrapSm {
  @include breakpoint(sm) {
    flex-wrap: nowrap;
  }
}
.nowrapMd {
  @include breakpoint(md) {
    flex-wrap: nowrap;
  }
}
.nowrapLg {
  @include breakpoint(lg) {
    flex-wrap: nowrap;
  }
}

@include breakpoint(sm) {
  .sm-1 {
    --col-width: 1;
  }

  .sm-2 {
    --col-width: 2;
  }

  .sm-3 {
    --col-width: 3;
  }

  .sm-4 {
    --col-width: 4;
  }

  .sm-5 {
    --col-width: 5;
  }

  .sm-6 {
    --col-width: 6;
  }

  .sm-7 {
    --col-width: 7;
  }

  .sm-8 {
    --col-width: 8;
  }

  .sm-9 {
    --col-width: 9;
  }

  .sm-10 {
    --col-width: 10;
  }

  .sm-11 {
    --col-width: 11;
  }

  .sm-12 {
    --col-width: 12;
  }
}


@include breakpoint(md) {
  .md-1 {
    --col-width: 1;
  }

  .md-2 {
    --col-width: 2;
  }

  .md-3 {
    --col-width: 3;
  }

  .md-4 {
    --col-width: 4;
  }

  .md-5 {
    --col-width: 5;
  }

  .md-6 {
    --col-width: 6;
  }

  .md-7 {
    --col-width: 7;
  }

  .md-8 {
    --col-width: 8;
  }

  .md-9 {
    --col-width: 9;
  }

  .md-10 {
    --col-width: 10;
  }

  .md-11 {
    --col-width: 11;
  }

  .md-12 {
    --col-width: 12;
  }
}

@include breakpoint(lg) {
  .lg-1 {
    --col-width: 1;
  }

  .lg-2 {
    --col-width: 2;
  }

  .lg-3 {
    --col-width: 3;
  }

  .lg-4 {
    --col-width: 4;
  }

  .lg-5 {
    --col-width: 5;
  }

  .lg-6 {
    --col-width: 6;
  }

  .lg-7 {
    --col-width: 7;
  }

  .lg-8 {
    --col-width: 8;
  }

  .lg-9 {
    --col-width: 9;
  }

  .lg-10 {
    --col-width: 10;
  }

  .lg-11 {
    --col-width: 11;
  }

  .lg-12 {
    --col-width: 12;
  }
}
@include breakpoint(xl) {
  .xl-1 {
    --col-width: 1;
  }

  .xl-2 {
    --col-width: 2;
  }

  .xl-3 {
    --col-width: 3;
  }

  .xl-4 {
    --col-width: 4;
  }

  .xl-5 {
    --col-width: 5;
  }

  .xl-6 {
    --col-width: 6;
  }

  .xl-7 {
    --col-width: 7;
  }

  .xl-8 {
    --col-width: 8;
  }

  .xl-9 {
    --col-width: 9;
  }

  .xl-10 {
    --col-width: 10;
  }

  .xl-11 {
    --col-width: 11;
  }

  .xl-12 {
    --col-width: 12;
  }
}

.justifyContent-flex-start {
  justify-content: flex-start;
}

.justifyContent-center {
  justify-content: center;
}

.justifyContent-space-between {
  justify-content: space-between;
}
.justifyContent-space-around {
  justify-content: space-around;
}

.justifyContent-flex-end {
  justify-content: flex-end;
}

.alignContent-stretch {
  align-content: stretch;
}

.alignContent-flex-start {
  align-content: flex-start;
}

.alignContent-center {
  align-content: center;
}

.alignContent-flex-end {
  align-content: flex-end;
}

.alignContent-space-between {
  align-content: space-between;
}

.alignContent-space-around {
  align-content: space-around;
}

.alignItems-stretch {
  align-items: stretch;
}

.alignItems-flex-start {
  align-items: flex-start;
}

.alignItems-center {
  align-items: center;
}

.alignItems-flex-end {
  align-items: flex-end;
}

.alignItems-baseline {
  align-items: baseline;
}

.direction-row {
  flex-direction: row;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.direction-column {
  flex-direction: column;
  flex-wrap: nowrap;
}

.direction-column-reverse {
  flex-direction: column-reverse;
  flex-wrap: nowrap;
}
