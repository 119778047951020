@import "shared/styles/tools";

@mixin progressBar {
  /* Slider - used for Volume bar and Progress bar */
  :global(.video-js) & :global(.vjs-slider) {
    line-height: 100%; // override the font line height.
    background-color: rgba(20, 20, 20, 0.6);
  }
  :global(.video-js) & :global(.vjs-play-progress .vjs-control-bar) {
    background-color: #00aaff;
  }

  /* The default color of control backgrounds is mostly black but with a little
    bit of blue so it can still be seen on all-black video frames; which are common. */
    :global(.video-js .vjs-control-bar) & {
    /* Opacity: 1.0 = 100%; 0.0 = 0% */
    background-color: rgba(0, 0, 0, 0);
  }
  :global(.video-js) & :global(.vjs-volume-vertical) {
    /* Opacity: 1.0 = 100%; 0.0 = 0% */
    background-color: rgba(0, 0, 0, 0);
  }

  /* The slider bar color is used for the progress bar and the volume bar */
  :global(.video-js) & :global(.vjs-slider-bar) {
    background: #00aaff;
  }

  /* The main progress bar also has a bar that shows how much has been loaded. */
  :global(.video-js) & :global(.vjs-load-progress) {
    /* Otherwise well rely on stacked opacities */
    background: rgba(#f5f5f5, 0.25);
  }

  :global(.video-js) & :global(.vjs-progress-control .vjs-progress-holder) {
    margin: 0;
  }
  // /* The load progress bar also has internal divs that represent
  //    smaller disconnected loaded time ranges */
  :global(.video-js) & :global(.vjs-load-progress) div {
    /* Otherwise well rely on stacked opacities */
    background: rgba(#f5f5f5, 0.35);
  }

  :global(.video-js) & :global(.vjs-progress-control) {
    align-items: flex-end;
  }
  :global(.video-js) & :global(.vjs-progress-control .vjs-progress-holder.vjs-slider.vjs-slider-horizontal) {
    font-size: 0.166667em;
    @include breakpoint(sm) {
      font-size: 0.25em;
    }
  }
  :global(.video-js) & :global(.vjs-progress-holder) {
    height: 1em;
  }
  :global(.video-js) & :global(.vjs-play-progress):before {
    font-size: 2em;
    color: #00aaff;

    height: 100%;
    display: flex;
    top: 0;
    align-items: center;
  }

  // progress bar mouse display
  :global(.video-js) & :global(.vjs-mouse-display),
  :global(.video-js) & :global(.vjs-time-tooltip) {
    font-style: normal;
    @include font($weight: semi-bold, $size: 14px);
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    @include breakpoint(sm) {
      font-size: 12px;
      letter-spacing: 0.04em;
    }
  }
}
