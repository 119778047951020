
/* CONTAINER POSITIONING */
.playStatusIndicator {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    background-color: #000;
    border-radius: 100%;
}
/* ANIMATION AND VISIBILITY */

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.animated {
    opacity: 0;
    width: 100px;
    height: 100px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.notAnimated {
    opacity: 0.6;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.animated .icon {
    font-size: 40px;
}
.animated .tableCenter {
    width: 80px;
}

/* ICON POSITIONING */

.icon {
    width:50%;
    font-size: 20px;
    cursor: pointer; 
    color: #fff;
    -webkit-transition: font 0.5s ease;
    -moz-transition: font 0.5s ease;
    -o-transition: font 0.5s ease;
    transition: font 0.5s ease;
}
.iconContainer {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.tableCenter {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 40px;
    height: 100%;
    display: table;
    vertical-align: middle;
    -webkit-transition: width 0.5s ease;
    -moz-transition: width 0.5s ease;
    -o-transition: width 0.5s ease;
    transition: width 0.5s ease;
}

.play {
    width:45%;
    transform: translateX(16.6666%)
}