/* Overlay */
 .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(100, 100, 100, 0.4));
  top: 0;
}

 .vjs-has-started .overlay {
  opacity: 1;
}

 .vjs-has-started.vjs-user-inactive.vjs-playing .overlay {
  opacity: 0;
  transition: visibility 1s, opacity 1s;
}

/* Player */
 .player-wrapper {
  height: 100%;
  -webkit-tap-highlight-color: transparent; // to remove the blue overlay on android
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  display: flex;
  background-color: #141414;
}

 .player-wrapper * {
  outline: none;
}
 .player-wrapper .video-js {
  max-width: 100%;
  width: auto;
  height: 100%;
  display: initial;
  flex: 1 0 auto;
}

 .player-wrapper .video-js .vjs-tech {
  position: static;
}
 .player-wrapper .video-js,
 .player-wrapper video,
 .player-wrapper .vjs-poster {
  background-color: black; // icon/background color
}

/* Google IMA SDK Fix for fluid player */
 .player-wrapper .video-js .ima-ad-container {
  top: 0;
}
/* COLORS */

$primary-color: #ffaa00;
$secondary-color: #141414;

$text-primary-color: #141414;
$text-secondary-color: #646464;
$text-reverse-color: #ffffff;
$text-rule-color: #d5d5d5;

$background-primary-color: #ffffff;
$background-secondary-color: #f5f5f5;
$background-reverse-color: #141414;
$background-overlay-color: rgba(20, 20, 20, 0.6);

$brand-primary-color: #ffaa00;
$brand-secondary-color: #00aaff;
$brand-tertiary-color: rgb(245, 245, 245);

$ui-action-color: rgb(0, 170, 255);
$ui-alert-color: #e00b0b;
$ui-alertReverse-color: #ff4545;

$icon-indicator-color: #ffffff;
$icon-background-color: #4b4b4b;
$icon-backgroundHover-color: #7e7e7e;

 .video-player .video-js {
  /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
  // font-size: 10px;

  /* The main font color changes the ICON COLORS as well as the text */
  color: $background-primary-color;
}

/* LAYOUT */

// control container.
 .player-wrapper .video-js .vjs-control-bar {
  height: 100%;
  width: 100%;
  overflow: hidden; // to fix the player flickering when some of the control elements went beyond the player scope.
  // we want to stop the control bar from handling the visibility to delegate that to each component
  background-color: transparent !important;
  opacity: 1 !important;
  visibility: visible !important;
}

// Big Play button
 .player-wrapper .video-js .vjs-big-play-button {
  display: none;
}
