@import "shared/styles/tools";

.root {
  flex: 1;
  display: flex;
  padding: 2px spacing(1);
  justify-content: center;

  background-color: transparent;
  color: #ffffff;

  &:hover {
    background-color: #d5d5d5;
    color: #141414;
  }

  &.selected {
    background-color: #ffffff;
    color: #141414;

    &:hover {
      background-color: #ffffff;
      color: #141414;
    }
  }
  span {
    text-transform: none; // override the uppercase from the font.
  }
}
