@import "shared/styles/tools";

.menuTitle {
  padding: spacing(1);
}
.separator {
  flex: 1 1 auto;
  height: 1px;
  margin: 0px spacing(1);
  box-sizing: border-box;
  background-color: #7e7e7e;
}
