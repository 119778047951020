:root {
  /* Number of Columns for Grid */
  --columns: 12;

    /* FONTS - Also available as Sass Variables Below */
    --source-serif: 'Source Serif Pro';
    --barlow: 'Barlow';

    --heading-barlow-1: 1.5rem;

    /* FONT WEIGHTS */
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
  
  /* SPACING UTILITIES */
  /* These help keep our spacing (padding and margin) consistent with an 8px unit per Figma file 
    * Use these to add spacing (padding or margin) between elemnts in place of normal units
    * E.g. margin-top: var(--padding-md) // to give an element a 16px/1rem top margin
    */
  --padding-sm: .5rem;  /* 8px */
  --padding-md: 1rem;   /* 16px */
  --padding-lg: 1.5rem; /* 24px */
  --padding-xl: 2rem;   /* 32px */

    /* PALETTE 
   * Use these for text, element and background colors 
   * Colors and naming from Figma file
   * https://www.figma.com/file/jmCGEQdFJ0Qq3rYF5RyoDb/Colors?node-id=88%3A89
  */
  --brand-primary: #FFAA00;
  --brand-secondary: #00AAFF;

  --text-primary: #141414;
  --text-secondary: #646464;
  --text-reverse: #ffffff;
  --text-rule: #d5d5d5;
  
  --bg-primary: #ffffff;
  --bg-secondary: #f5f5f5;
  --bg-reverse: #141414;
  --bg-overlay: rgba(20, 20, 20, 0.6);
  
  --ui-action: #00AAFF;
  --ui-alert: #E00B0B;
  --ui-alert-reverse: #FF4545;
  --ui-url: #0085C8;
  
  --icon-image: #ffffff;
  --icon-bg: #4B4B4B;
  --icon-bg-hover: #7E7E7E;
}
