@import "shared/styles/tools";

@mixin remainingTime {
  :global(.video-js) & :global(.vjs-remaining-time.vjs-time-control) {
    color: #ffffff;
    font-style: normal;
    @include font($weight: semi-bold, $size: 14px);
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    align-items: center;
    display: flex;
    width: auto;
    padding: 0;
    margin: 0px 24px 0px 8px;
    min-width: 3.2em; // enough to fit most of the times without needing to resize.
    @include breakpoint(sm) {
      // using the settings as reference = 3em - 1.3em + 0.15em, in order are, button width, image width, right margin
      margin-right: 1.85em;
      font-size: 12px;
      letter-spacing: 0.04em;
    }
    @include breakpoint(lg) {
      justify-content: flex-end;
      margin-left: 0;
    }
    :global(.vjs-remaining-time-display) {
      text-align: left;
    }
  }
}
