@import "shared/styles/tools";

.container {
  padding: 0 1.5rem 1rem 1.5rem;
  background-color: #F4F4F7;
  h2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #a4a4a4;
    width: 100%;
    text-align: center;
    font-weight:100;
    font-size: 1rem;
  }
}

.headerSlot {
  padding-top: 1rem;
}

.adWrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 325px;
  max-width: 970px;
  min-height: 50px;
}