html,
body {
  font-family: var(--barlow);
  font-weight: 400;
  height: auto;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}
/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}
/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}
a {
  text-decoration: none;
  color: inherit;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;

  outline: none;
  &:focus {
    box-shadow: 0 0 1px 3px var(--brand-secondary);
  }
}
/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Light'),
       url('/fonts/Barlow-Light-webfont.woff2') format('woff2') format('woff2'),
       url('/fonts/Barlow-Light-webfont.woff') format('woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow'),
       url('/fonts/barlow-v5-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/barlow-v5-vietnamese_latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: local('Barlow SemiBold'),
       url('/fonts/barlow-v5-vietnamese_latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/barlow-v5-vietnamese_latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: local('Barlow Bold'),
       url('/fonts/barlow-v5-vietnamese_latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/barlow-v5-vietnamese_latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}


@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Serif Pro'),
       url('/fonts/source-serif-pro-v11-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/source-serif-pro-v11-vietnamese_latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* source-serif-pro-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Serif Pro'),
       url('/fonts/source-serif-pro-v11-vietnamese_latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/fonts/source-serif-pro-v11-vietnamese_latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}