@import "shared/styles/tools";

.root {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  :global(.vjs-error) & {
    display: flex;
  }
}
.container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  flex-direction: column;
}
.active {
  display: flex;
}
.inactive {
  display: none;
}
.errorMessage {
  font-size: 16px;
  flex: 0 1 0;
  padding: spacing(2) spacing(3);
  text-align: center;
}
.retryContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.retryButton {
  width: 4em;
  height: 4em;
}
