@import "shared/styles/tools";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 800px;
  text-align: center;
  background-color: var(--text-primary);
  color: white;
  padding: 0 spacing(2);

  @include breakpoint(md) {
    min-height: 700px;
  }

  @include breakpoint(sm) {
    min-height: 500px;
  }

  h1 {
    color: var(--brand-primary);
    font-size: 100px;
    font-weight: var(--font-normal);
  }
  p {
    @include font($weight: semi-bold, $size: 2rem);
  }
}