@import "shared/styles/tools";
@import "shared/styles/elements";

.headerContainer {
  margin-top: 2.5rem;
}

.headerContent {
  padding-left: var(--padding-lg);
  --padding-watch-header: var(--padding-lg);

  @include breakpoint(sm) {
    margin-top: var(--padding-md);
    --padding-watch-header: 0
  }

  h1 {
    color: var(--text-primary);
    font-family: var(--source-serif);
    font-weight: var(--font-regular);
    font-size: 2.375rem;
    line-height: 120%;
    margin-top: spacing(3);

    @include breakpoint(md) {
      font-size: 2rem;
      margin-top: spacing(1);
    }

    @include breakpoint(sm) {
      margin-top: spacing(2);
      margin-bottom: spacing(2);
    }
  }
}

.metaContent {
  font-size: 0.875rem;
  text-transform: uppercase;

  @include breakpoint(md) {
    font-size: 0.775rem;
  }
}

.section {
  color: var(--brand-secondary);
  @include font($weight: semi-bold, $size: 0.875rem);

  a:hover {
    text-decoration: underline;
  }
}

.date {
  color: var(--text-secondary);
  margin-left: 2.875rem;
  
  @include breakpoint(md) {
    margin-left: 1.275rem;
  }
}

.socialIcons {
  margin-top: spacing(3);
  margin-bottom: spacing(3);

  @include breakpoint(md) {
    margin-top: spacing(1);
    margin-bottom: spacing(1);
  }

  @include breakpoint(sm) {
    margin-top: spacing(2);
    margin-bottom: spacing(2);
  }

  > a {
    margin-left: var(--padding-md);

    svg {
      fill: black;

      &:hover {
        fill: var(--brand-primary);
      }
    }
  }
}

.description {
  border-top: 1px solid var(--text-primary);
  padding-top: spacing(2);
  line-height: 1.3;
  
  @include breakpoint(md) {
    padding-top: spacing(1);
    font-size: 0.75rem;
  }
  
  @include breakpoint(lg) {
    padding-top: spacing(2);
  }

}

.relatedContentContainer {
  background-color: var(--bg-reverse);
  width: 100%;
}


.relatedContent {
  margin-left: auto;
  margin-right: auto;
  padding: 2.25rem var(--padding-16-24) 3.375rem var(--padding-16-24);
  max-width: 1440px;
  width: 100%;
  
  @include breakpoint(md) {
    padding-top: 1.5rem;
  }

  @include breakpoint(sm) {
    padding-top: 2rem;
  }
}

.relatedContentVideos {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--padding-lg);
  margin-top: 2.5rem;
  
  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 0;
  }
  
  @include breakpoint(lg) {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 0;
  }

  div:hover {
    p {
      color: var(--text-rule);
    }
  }
}

.relatedContentTitle {
  color: var(--text-reverse);
  font-family: var(--barlow);
  font-weight: var(--font-semi-bold);
  font-size: 1.5rem;
  line-height: 130%;
  margin-bottom: var(--padding-lg);
  text-transform: uppercase;

  @include breakpoint(sm) {
    text-align: center;
  }
}

.bottomContent {
  flex-shrink: 10;
}

.fadeIn {
  animation: fadeIn .5s ease-in;
}

@keyframes fadeIn {
  from {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
}

.article {
  border-top: 1px solid black;
	margin-top: spacing(5);
  line-height: 1.3;

  * {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint(sm) {
    margin-top: spacing(2);
  }

  .socialIcons {
    margin-bottom: spacing(2);

    @include breakpoint(md) {
      margin-top: spacing(2);
    }
  }
}

.articleText {
  * + * {
    margin-top: spacing(2)
  }

  a {
    color: var(--brand-secondary);
    &:hover {
      text-decoration: underline;
    }
  }
}