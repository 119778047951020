@import 'shared/styles/tools';

.root {
  border-top: 1px solid black;
  width: 100%;
}

.footer {
  padding: 3rem 1.5rem 2rem;
  @include breakpoint(sm) {
    text-align: center;
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 1.5rem 0;
    @include breakpoint(sm) {
      margin: 1rem 0 2.5rem;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  }

  h1 {
    font-family: var(--source-serif);
    font-weight: var(--font-semi-bold);
    margin-bottom: 1.5rem;
    @include breakpoint(sm) {
      font-size: 1.5rem;
      width: 100%;
    }
  }

  h2 {
    text-transform: uppercase;
    @include font($weight: semi-bold);
  }

  p {
    @include font($weight: regular);
    margin-bottom: 2rem;
  }

  aside {
    @include font($weight: medium);

    a {
      text-decoration: underline;
      
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  a:hover {
    text-decoration: underline;
  }
}

