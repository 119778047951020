// testing
.video-js .vjs-play-control {
  background-color: transparent;
  color: #ffffff;
  position: relative;
  display: contents;
}

.video-js .vjs-play-control > .vjs-icon-placeholder:before {
  display: contents;
}
