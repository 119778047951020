@import "shared/styles/tools";

.header {
  border-bottom: 1px solid black;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 0 1.5rem;
  height: 5rem;
  align-items: center;
}

.logo {
  flex-grow: 1;
  text-align: center;
  @include breakpoint(lg) {
    flex-basis: 12rem;
    flex-shrink: 2.5;
    flex-grow: initial;
    min-width: calc(160px + 0.5rem);
  }
  img {
    display: inline;
    margin: 0 auto;
    @include breakpoint(lg) {
      margin: initial;
      display: block;
    }
  }
}

.nav {
  display: flex;
  flex-direction: row;
  flex-basis: 714px;
  justify-content: space-around;

  * {
    display: inherit;
    text-transform: uppercase;
    @include font($weight: medium);

    &:hover {
      color: var(--text-secondary);
    }
  }

  .active {
    text-decoration: underline;
  }

  a:hover {
    color: var(--text-secondary);
  }
}

.mobileNavButton {
  cursor: pointer;
}

.mobileNav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: #{"min(100%, 400px)"};
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 5;

  header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    border-bottom: 1px solid black;

    @include breakpoint(md) {
      height: 5rem;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    a {
      text-align: center;
      padding: 2rem 0;
      border-bottom: 1px solid #c4c4c4;
      margin: 0 1rem;
      text-transform: uppercase;

      @include breakpoint(md) {
        padding: 3rem 0;
      }

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
}

.navOverlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--bg-overlay);
  width: 100%;
  height: 100%;
  z-index: 4;
}

.cta {
  margin-left: auto;
  white-space: nowrap;
  background-color: var(--brand-primary);
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  border: 1px solid var(--brand-primary);
  outline: none;

  &:hover {
    background-color: var(--bg-primary);
    border-color: var(--text-primary);
  }

  &:focus {
    box-shadow: 0 0 1px 2px var(--brand-secondary);
  }
}

.mobileCta {
  text-align: center;
  background-color: var(--brand-primary);
  padding: 1rem;
  border-radius: 1.5rem;
  margin: 2rem 1rem 0;
  border: 1px solid var(--brand-primary);

  @include breakpoint(md) {
    margin: 3rem 1rem 0;
  }

  &:hover,
  &:active {
    background-color: var(--bg-primary);
    border-color: var(--text-primary);
  }
}

.social {
  margin-top: 2rem;
  text-align: center;

  @include breakpoint(md) {
    margin-top: 3rem;
  }

  * {
    margin: 0 0.5rem;

    svg {
      fill: var(--text-primary);

      &:hover,
      &:active {
        fill: var(--brand-primary);
      }
    }
  }
}

.closeButton:hover {
  cursor: pointer;
}