.word {
  display: inline-block;
  width: 15%;
  height: 12px;
  margin-bottom: 1rem;
  margin-right: .5rem;
}
.text {
  width: 100%;
  height: 12px;
  margin-bottom: 1rem;
}
.title {
  width: 100%;
  height: 20px;
  margin-bottom: 2rem;
}

.image,
.image-lg {
  width: 100%;
  height: 150px;
  margin-bottom: 2rem;
  padding: 1rem;

  svg {
    display: inline-block !important;
    height: 20%;
    width: 20%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: spinning infinite ease-in-out 2s;
  }
}

.image-lg {
  height: 400px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.thumbnail {
  width: 100px;
  height: 100px;
}
.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
}

.skeleton-profile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  align-items: center;
}

.loading {
  animation: loading 1s infinite;
  background-image: linear-gradient(100deg, #eceff1, #f6f7f8 50%, #eceff1 70%);
  background-size: 300%;
  border-radius: .1rem;
  color: transparent;

  svg {
    display: none;
  }
}


@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes spinning {
  from {
   transform: translate(-50%, -50%) rotate(0deg);
   -webkit-transform: -webkit-translate(-50%, -50%) -webkit-rotate(0deg);
 }
 to {
   transform: translate(-50%, -50%) rotate(360deg);
   -webkit-transform: -webkit-translate(-50%, -50%) -webkit-rotate(360deg);
 }
}


.header {
  padding: 2rem;
}

.relatedContentContainer {
  background-color: var(--bg-reverse);
  padding-bottom: 3.375rem;
  padding-top: 2.25rem;
}
