@import "vars";
@import "tools";
@import "resets";

/* Visibility */
.hide { display: none !important; }

@include breakpoint(sm) {
  .hide\@sm { display: none !important; }
  .display\@md { display: none !important; }
  .display\@lg { display: none !important; }
  .display\@xl { display: none !important; }
}
@include breakpoint(md) {
  .display\@sm { display: none !important; }
  .hide\@md { display: none !important; }
  .display\@lg { display: none !important; }
  .display\@xl { display: none !important; }
}
@include breakpoint(lg) {
  .display\@sm { display: none !important; }
  .display\@md { display: none !important; }
  .hide\@lg { display: none !important; }
  .display\@xl { display: none !important; }
}
@include breakpoint(xl) {
  .display\@sm { display: none !important; }
  .display\@md { display: none !important; }
  .hide\@xl { display: none !important; }
}

/* Dynamic CSS Variables Responsive values */
@include breakpoint(sm) {
  :root {
  /* RESPONSIVE PADDING
    * Responsive padding takes the following format
    * --padding-[small]-[medium]-[large] 
    * Examples:
    * --padding-16-24      // 16px padding @ sm; 24px padding @md and up
    * --padding-16-24-32   // 16px padding @ sm; 24px padding @md and 32px padding @ lg and up
    * --padding-24-24-32   // 24px padding @ sm; 24px padding @md and 32px padding @ lg and up
    *
    * Use these for padding and/or margin around content
    * The Grid component uses these to add padding around content as per Figma designs
    */
    --padding-16-24: 1rem;
    --padding-16-16-32: 1rem;

    /* FONT SIZES 
      * Annotated the font sizes corresponding with headings and body text in hompepage designs
      * These are added to typography classes in global.css but can be used throught the site 
      * as prescribed in Figma designs
    */
    --heading-source-1: 2rem;   /* hero */
    --heading-source-2: 2.5rem; /* podcast */
    --heading-source-3: 1.5rem; /* slogan */
    --heading-source-4: 2rem;   /* newsletter subheading */
  
    --heading-barlow-1: 1.5rem;   /* wire && verticals && newsletter*/
    --heading-barlow-3: 0.875rem; /* footer nav titles */
  
    --body-text: 1rem;
  }
}

@include breakpoint(md) {
  :root {
    --padding-16-24: 1.5rem;

    --heading-source-1: 2rem;
    --heading-source-2: 2.8125rem;
    --heading-source-3: 2rem;
    --heading-source-4: 2.8125rem;
    --heading-barlow-3: 0.75rem;
    
    --body-text: 0.75rem;
  }
}
@include breakpoint(lg) {
  :root {
    --padding-16-24: 1.5rem;

    --heading-source-1: 2.375rem;
    --heading-source-2: 2.8125rem;
    --heading-source-3: 2rem;
    --heading-source-4: 2.8125rem;
    --heading-barlow-3: 0.75rem;
    
    --body-text: 0.75rem;
  }
}
// @include breakpoint(xl) {
//   :root {
//     --padding-16-24: 1.5rem;
//     --padding-16-16-32: 2rem;

//     --heading-source-1: 2.375rem;
//     --heading-source-2: 2rem;
//     --heading-barlow-3: 0.875rem;

//     --body-text: 1rem;
//   }
// }
