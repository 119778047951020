@import 'shared/styles/tools';
@import './DefaultControlsStyles/progress-bar';
@import './DefaultControlsStyles/remaining-time';
@import './DefaultControlsStyles/volume';

.root {
  // positioning
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  // visibility

  :global(.video-js.vjs-has-started.vjs-user-active) & > *,
  :global(.video-js.vjs-has-started.vjs-user-inactive.vjs-paused) & > * {
    transition: opacity 1s;
    opacity: 1;
    z-index: 1;
  }
  :global(.video-js.vjs-has-started.vjs-user-inactive) & > * {
    transition: opacity 1s;
    opacity: 0;
    z-index: 1;
  }

  :global(.video-js) & {
    font-style: normal;
    @include font($weight: semi-bold, $size: 14px);
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    @include breakpoint(sm) {
      font-size: 12px;
      letter-spacing: 0.04em;
    }
  }
  :global(.video-js) & > * {
    font-style: normal;
    @include font($weight: semi-bold, $size: 1.7142em);
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    @include breakpoint(sm) {
      font-size: "12px";
      letter-spacing: "0.04em";
    }

    // sizing
    @include breakpoint(sm) {
      font-size: 1.3333334em;
    }
  }

  // override default icon sizing
  :global(.video-js) & :global(.vjs-button) {
    height: 100%;
  }
  :global(.video-js) & :global(.vjs-button > .vjs-icon-placeholder) {
    line-height: 1em;
    font-size: 1em;
  }
  :global(.video-js) & :global(.vjs-button > .vjs-icon-placeholder):before {
    font-size: 1em;
    line-height: 3em; // To center the VideoJS Family Font
  }

  @include progressBar;
  @include remainingTime;
  @include volume;
}
.background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0; // we need to show it behind its siblings
  background-image: radial-gradient(at center, transparent 30%, rgba(20, 20, 20, 0.6) 100%);

  @include breakpoint(sm) {
    background-color: rgba(20, 20, 20, 0.6);
    background-image: none;
  }
}
.controlBar {
  display: flex;
  flex-direction: row;
  min-height: 0;
  min-width: 0;
  pointer-events: none;
  * {
    pointer-events: all; // for the custom controls.
  }
}
.upperControlsContainer {
  flex: 0 0 2.758em;
  display: flex;
  flex-flow: row-reverse;
  :global(.video-js .vjs-control-bar) & {
    z-index: 20; // otherwise itll show the quality menu behind the up next button.
  }
  > span {
    // used to separate between left and right controls
    flex-grow: 1;
    pointer-events: none;
  }
}
.middleControlsContainer {
  flex: 1 0 3em;
  align-items: center;
  justify-content: center;
  :global(.video-js) & > * {
    font-size: 1.666667em;
    @include breakpoint(sm) {
      font-size: 2em;
    }
  }
}
.bottomControlsContainer {
  justify-content: space-between;
  padding: 0;
  flex: 0 0 1em;

  @include breakpoint(sm) {
    flex: 0 0 1.5em;
  }
  & > span {
    // used to separate between left and right controls
    flex-grow: 1;
    pointer-events: none;
  }
}
.progressBarContainer {
  flex: 0 0 1em;
  @include breakpoint(lg) {
    flex: 0 0 0.83em;
  }
  :global(.video-js.vjs-has-started) & {
    opacity: 1; // we never hide the progress bar.
  }

  :global(.video-js.vjs-has-started) & :global(.vjs-play-progress.vjs-slider-bar):before {
    transition: opacity 1s;
    opacity: 1;
  }
  :global(.video-js.vjs-has-started.vjs-user-inactive) & :global(.vjs-play-progress.vjs-slider-bar):before {
    transition: opacity 1s;
    opacity: 0;
  }
}
.tapCover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000 !important; // otherwise it'll show the quality menu and volume.
  user-select: none;
  pointer-events: none;
  .enabled {
    pointer-events: all;
  }
}
