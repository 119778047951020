@import "shared/styles/tools";

.videoTileContainer {
  position: relative;
  width: 100%;
  @include breakpoint(sm) {
    line-height: 0;
  }
}

.videoContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  @include breakpoint(sm) {
    width: 50%;
  }
}

.thumbnail {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  background-color: #F5F5F5;
}


.videoMeta {
  font-style: normal;
  font-weight: var(--font-regular);
  line-height: 130%;
  color: var(--text-primary);
  font-family: var(--source-serif);
  margin-top: var(--padding-sm);
  font-size: 1.125rem;
  display: inline-block;
  overflow-wrap: break-word;
  @include breakpoint(sm) {
    width: 50%;
    padding-left: var(--padding-md);
    vertical-align: top;
    margin-top: 0;
  }
}

.videoDate {
  color: var(--text-secondary);
  font-family: var(--barlow);
  letter-spacing: 0.04em;
  line-height: 130%;
  text-transform: uppercase;
  margin-bottom: var(--padding-sm);
  font-size: 0.75rem;
  
}

.reverse {
  color: var(--text-reverse);
}

.tileDivider {
  margin-block-start: var(--padding-md);
  margin-block-end: var(--padding-md);
}

.playButton {
  background-color: var(--bg-reverse);
  border-radius: var(--padding-lg);
  border: 1px solid var(--text-reverse);
  color: var(--text-reverse);
  
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  
  padding: 1% 4.5%;
  position: absolute;
  
  bottom: 8%;
  left: 6%;
  
  min-width: 20%;
  z-index: 2;

  svg {
    height: auto;
    width: 16%;
    
    @include breakpoint(sm) {
      width: 15%;
    }
  }
  
  span {
    font-size: 0.875rem;
    font-size: 3vw;
    margin-left: 5px;
    
    @include breakpoint(md) {
      font-size: 2vw;
    }
    
    @include breakpoint(lg) {
      font-size: 1.15vw;
      font-size: clamp(0.875rem, 1.15vw, 1vw);
    }
  }
}
