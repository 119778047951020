@import "shared/styles/tools";

.root {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 2.83em;
  pointer-events: none;
  display: flex;

  &.isMobile {
    display: none;
  }

  @include breakpoint(sm) {
    width: 3em;
    margin-left: 0.15em;
    display: none;

    &.isMobile {
      display: flex;
    }
  }

  * {
    pointer-events: all;
  }

  :global(.video-js) & :global(.vjs-play-control.vjs-button > .vjs-icon-placeholder):before {
    font-size: 1.5em;
    position: relative; // otherwise it's misaligned in firefox
  }
  img {
    width: 20px;
    height: 16px;
    @include breakpoint(sm) {
      width: 1.3em;
      height: 1.3em;
    }
  }
  :global(.vjs-fullscreen) & img {
    content: url("/icons/exitfullscreen.svg");
  }
}
