@import "shared/styles/tools";
@import "shared/styles/elements";

.recirculationContainer {
  margin-top: 2.5rem;
}

.sectionTitle {
  font-weight: 600;
  font-family: var(--barlow);
  font-size: var(--heading-barlow-1);
  text-transform: uppercase;
}
.buttonContainer {
  margin-top: var(--padding-lg) !important;
  margin-bottom: var(--padding-lg);

  .button {
    @include btnPill();
    @include btnDark();
    cursor: pointer;
    height: 55px;
    width: 100%;
    &:hover {
      color: var(--brand-primary);
    }
  }
}

.buttonGroup {
  position: absolute;
  top: -3.5rem;
  right: 0;
  
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    svg {
      fill: var(--text-primary);
    }

    &:hover {
      svg {
        fill: var(--brand-primary);
      }
    }

    &:disabled,
    &[disabled] {
      opacity: .4;
      &:hover {
        svg {
          fill: black;
        }
      }
    }
  }
}





.carouselContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding: 0 var(--padding-16-24);
  position: relative;
  margin-top: var(--padding-md);
  @include breakpoint(lg) {
    margin-top: var(--padding-lg);
  }

  width: 100%;

}

.tileContainer {
  position: relative;

  &::before {
    background-color: var(--text-rule);
    content: '';
    display: block;
    height: 1px;
    opacity: .3;
    position: absolute;
    top: 2px;
    width: 110%;
  }
}

.tile {
  p {
    overflow: hidden;
  }
  
  &:hover {
    p {
      color: var(--text-secondary);
    }
  }
}

.spacer {
  opacity: 0;
}

.meta {
  font-family: var(--barlow);
  font-size: 0.75rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-transform: uppercase;
  gap: .5rem;

  &::before {
    background-color: var(--ui-alert-reverse);
    border-radius: 18px;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: -2px;
    width: 9px;
  }


}

.slider {
  position: relative;
}

.container {
  position: relative;
  width: 100%;
  overflow-x: hidden;



  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  --col: 50;
  --position: 0;
  transition-property: left;
  transition-duration: .3s;
  position: relative;
  width: 100%;
  top: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  
  grid-template-columns: repeat(var(--col), calc(16.67% - 1.25rem));
  left: calc(var(--position) * (100% + 1.5rem));
  
  @include breakpoint(md) {
    grid-template-columns: repeat(var(--col), calc(25% - 1.125rem));
    left: calc(var(--position) * (100% + 1.5rem));
  }
  
  @include breakpoint(sm) {
    grid-template-columns: repeat(1, 100%);
    grid-auto-flow: row;
    left: 0;
    grid-gap: 0rem;
  }
}








