@import "shared/styles/tools";

.root {
  display: flex;
  flex: 0 1 auto;
  position: relative;
  width: 2.83em;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include breakpoint(sm) {
    margin-right: 0.15em;
    width: 3em;
    img {
      width: 1.3em;
      height: 1.3em;
    }
  }
  
  &:hover > div,
  &:focus > div {
    max-height: 300px;
    transition: max-height 0.3s;
  }

  &.hidden {
    display: none;
  }
}
.settingsMenu {
  background-color: rgba(20, 20, 20, 0.6);
  font-style: normal;
  @include font($weight: semi-bold, $size: 14px);
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include breakpoint(sm) {
    font-size: 12px; // Seems to be broken in old version
    letter-spacing: 0.04em;
  }

  display: flex;
  flex-direction: column;
  z-index: 500;
  overflow: scroll;
  max-height: 0;
  transition: max-height 0.3s;

  position: absolute;
  top: calc(100%);
  right: spacing(1); // to avoid being stuck to the edge.

  box-sizing: border-box;
}
.menuTitle {
  padding: spacing(1);
}
.separator {
  flex: 1 1 auto;
  height: 1px;
  margin: 0 spacing(1);
  box-sizing: border-box;
  background-color: #7e7e7e;
}
