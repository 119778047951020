@import 'shared/styles/tools';

.container {
  background: #500083;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 50px 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  max-width: 1440px;
  margin: 36px auto 0 auto;
  @include breakpoint(sm) {
    flex-direction: row;
    padding: 25px;
  }
}

.donationHeader {
  display: flex;
  padding-bottom: 32px;
  font-size: 42px;
  line-height: 120%;
  @include breakpoint(md) {
    padding: 0 0 20px 0;
  }

  @include breakpoint(sm) {
    padding: 0 0 20px 0;
  }
}

.donationBody {
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @include breakpoint(lg) {
    gap: 80px;
  }

  @include breakpoint(md) {
    flex-direction: column;
  }

  @include breakpoint(sm) {
    flex-direction: column;
  }
}

.donationSubheader {
  display: flex;
  flex-basis: 36%;
  flex-direction: column;
  font-size: 16px;

  @include breakpoint(md) {
    padding: 0 0;
  }

  @include breakpoint(sm) {
    padding: 0 0rem 0 0rem;
  }
}

.donationSection {
  display: flex;
  flex-basis: 64%;
  flex-direction: column;
  @include breakpoint(md) {
    margin-top: 30px;
  }
}

.donationButton {
  width: 50%;
  max-width: 350px;
  min-width: 200px;
  margin: 1.5rem 0 0 0;
  background-color: var(--brand-primary);
  color: var(--text-primary);
  padding: 0.5rem 1rem;
  border-radius: 72px;
  font-weight: bold;
  cursor: pointer;
  overflow: unset;
  border: none;
  font-size: 20px;

  @include breakpoint(sm) {
    margin: 1.5rem 0 0 0;
    width: 100%;
    max-width: none;
  }
}

.donationCard {
  display: flex;
  flex-direction: column;
}

.donationCardTitle {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 120%;
  font-weight: 400;
  color: var(--brand-primary);
  @include breakpoint(sm) {
    height: auto;
    font-size: 24px;
  }
}

.donationCardDescription {
  font-size: 14px;
  margin-bottom: 0.5rem;

  @include breakpoint(sm) {
    font-size: 18px;
  }
}

.donationSectionHeader {
  display: flex;
  font-size: 24px;
  line-height: 120%;
}

.donationSectionListDivider {
  opacity: 0.2;
  border: 1px solid #f0f0f0;
  width: 100%;
}

.donationSectionList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1.25rem 0;
  @include breakpoint(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.donationSubtext {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  color: #ffffff;
  font-style: normal;
  font-size: 16px;

  @include breakpoint(sm) {
    margin: 1rem 0;
    font-size: 18px;
  }
}

.donationSubheaderCaption {
  margin-top: 2.5rem;
}

.donationSubheaderBody {
  @include breakpoint(sm) {
   display: none;
  }
}