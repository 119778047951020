@import "shared/styles/tools";

.videoTileContainer,
.videoContainer {
  position: relative;
  width: 100%;
}

.videoTileContainer {
  &:hover {
    cursor: pointer;
    
    p {
      color: var(--text-secondary);
    }
  }
}

.thumbnail {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  background-color: #F5F5F5;
}

.videoTitle {
  color: var(--text-primary);
  font-family: var(--source-serif);
  font-style: normal;
  font-weight: normal;
  line-height: 130%;
  margin-top: var(--padding-sm);

  @include breakpoint(sm) {
    font-size: 1.125rem;
  }
  @include breakpoint(md) {
    font-size: 1.125rem;
    margin-top: var(--padding-md);
  }
  @include breakpoint(lg) {
    font-size: 1.25rem;
    margin-top: var(--padding-md);
  }
}

.videoSubtitle {
  font-size: 0.75rem;
  margin: 0;
  text-align: left;
  color: var(--text-secondary);
}

.reverse {
  color: var(--text-reverse);
}

.playButton {
  --scale: 1;
  background-color: var(--bg-reverse);
  border-radius: var(--padding-lg);
  border: 1px solid var(--text-reverse);
  color: var(--text-reverse);
  
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  
  padding: .15rem .5rem;
  position: absolute;
  bottom: 8%;
  left: 6%;
  width: 65px;
  
  z-index: 2;
  
  svg {
    height: 0.8125rem;
    width: 0.625rem;
  }
  
  span {
    font-size: 0.875rem;
    margin-left: 5px;
  }

  transform: scale(calc(var(--scale) / 300));
  transform-origin: bottom left;
}

.youtubePlayer {
  aspect-ratio: 16 / 9;
}

.youtubePlayerIframe {
  width: 100%;
  height: 100%;
  border: none;
}
